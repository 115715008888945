import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faQuoteRight,
  faQuoteLeft,
  faAward
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PaymentModal from '../../components/PaymentModal'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import i18n from '../../locales/he.yaml'
import { prettyPrice } from '../../utils'
import { fireEvent } from '../../analytics'

const course = i18n.courses.nonAcademyCourses.find(
  (course) => course.name === 'לחיות במקסימום שלך'
)

const AbundanceQuery = graphql`
  query AbundanceQuery {
    image1: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-קומות-של-פלמינגו-מעוצבת-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "אריאל-מחייך-ויושב-על-שפת-בריכה.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image3: file(relativePath: { eq: "רפיקי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image4: file(
      relativePath: { eq: "קארין-עמנואל-עם-עוגת-גי׳ני-של-אלאדין-בתלת-מימד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    image5: file(relativePath: { eq: "אריאל-בחול-מושיט-ידיים-למעלה.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const AbundanceTemplate = ({ pathname, data, product }) => {
  const [isPaymentForm, setIsPaymentForm] = useState(false)

  const { image1, image2, image3, image4, image5 } = data

  const IMAGES = [image1, image2, image3, image4, image5]

  const CTA = () => (
    <a
      href='#registration'
      className='button is-nude is-medium'
      onClick={() =>
        fireEvent('InternalClick', product, {
          to: '#registration'
        })
      }
    >
      {course.callToAction}
    </a>
  )

  return (
    <>
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={image1}
          successLabel={course.successLabel}
          course={course}
        />
      )}
      <BackToTopButton selectorId='abundance' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {course.name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-8 is-full-touch'>
                <h1 className='has-text-nude'>{course.subtitle}</h1>
                <h2 className='is-size-4'>{course.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {course.bullets.map((bullet, index) => (
        <div
          key={index}
          className='hero'
          id={index === 1 ? 'abundance' : undefined}
        >
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <ScrollOnAnimation key={bullet.title}>
                    <div className='columns is-flex-direction-column-touch'>
                      <div className='column'>
                        <h4 className='has-text-centered-touch'>
                          {bullet.title}
                        </h4>
                        <p>{bullet.description}</p>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen': index % 2 === 0
                      }
                    )}
                  >
                    <GatsbyImage
                      image={getImage(IMAGES[index])}
                      title={bullet.image.title}
                      alt={bullet.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(IMAGES[index]).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {bullet.image.title}
                      </p>
                    </div>
                  </div>
                </ScrollOnAnimation>
              </div>
            </div>
          </div>
          {(index === 0 || index === 2) && (
            <ScrollOnAnimation>
              <div className='hero-body has-text-centered content'>
                <CTA />
              </div>
            </ScrollOnAnimation>
          )}
        </div>
      ))}
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-full-touch is-8'>
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className='has-text-nude is-margin-bottom-4'
                    size='lg'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{course.howItWorks.title}</h3>
                  </div>
                  <p>{course.howItWorks.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faAward}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div className='is-margin-bottom-4'>
                <h3>{course.responsibility.title}</h3>
              </div>
              <p className='is-margin-bottom-6'>
                {course.responsibility.description}
              </p>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div id='registration' className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container has-text-centered content'>
              <h3>{course.pricing.title}</h3>
              <div className='has-text-centered is-family-secondary block'>
                <span className='is-block is-size-5 has-text-weight-bold'>
                  {prettyPrice(course.pricing.paymentPrice)}{' '}
                  {i18n.courses.currency}
                </span>
              </div>
              <p className='has-text-weight-bold is-margin-bottom-6'>
                {course.pricing.suffix}
              </p>
              <button
                className='button is-nude is-medium'
                onClick={() => setIsPaymentForm(true)}
              >
                {course.pricing.callToAction}
              </button>
            </div>
            <p className='help has-text-centered is-size-6 is-margin-top-6'>
              {i18n.courses.paymentInfo.pci}
            </p>
          </div>
        </div>
      </ScrollOnAnimation>
      {course.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <ScrollOnAnimation>
                  <div className='has-text-centered is-margin-bottom-6'>
                    <h3>{course.testimonials.title}</h3>
                  </div>
                </ScrollOnAnimation>

                {course.testimonials.testimonials.map((testimony, index) => (
                  <ScrollOnAnimation key={testimony.title}>
                    <div className='columns is-centered'>
                      <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                        <div
                          className={clsx(
                            'columns is-flex-direction-column is-mobile is-align-items-center',
                            {
                              'direction-column-reverse': testimony.isEnglish
                            }
                          )}
                        >
                          <div className='column is-align-self-flex-start'>
                            <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                          </div>
                          <div
                            className='column is-padding-top-0 is-padding-bottom-0'
                            dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                          >
                            <h4 className='has-text-gold-black is-margin-bottom-0'>
                              {testimony.title}
                            </h4>
                          </div>
                          <div className='column is-align-self-flex-end'>
                            <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                          </div>
                        </div>
                        <div
                          className={clsx('columns is-align-items-start', {
                            'direction-row-reverse': testimony.isEnglish
                          })}
                        >
                          <div className='column is-narrow'>
                            <p className='has-text-centered has-text-weight-bold'>
                              {testimony.name}
                            </p>
                          </div>
                          <div className='column'>
                            <p
                              className='is-margin-bottom-0'
                              dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                            >
                              {testimony.text}
                            </p>
                          </div>
                        </div>
                        {index <
                          course.testimonials.testimonials.length - 1 && <hr />}
                      </div>
                    </div>
                  </ScrollOnAnimation>
                ))}
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body has-text-centered content'>
              <CTA />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Abundance = ({ location: { pathname, search } }) => {
  const product = {
    category: course.greenInvoiceItemId,
    name: course.name,
    price: course.pricing.paymentPrice * course.pricing.numOfPayments
  }
  const data = useStaticQuery(AbundanceQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={course.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image1),
          alt: course.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: course.name,
              path: '/academy/abundance/'
            }
          ]
        }}
      />
      <AbundanceTemplate
        pathname={pathname}
        search={search}
        data={data}
        product={product}
      />
    </Layout>
  )
}

export default Abundance
